import React from 'react'
import '../css/home.css';
import Login from './Login';
export default function Home() {
  return (
    <div className='home'>
    
    <Login />
    </div>
  )
}
